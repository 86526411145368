import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import Image from 'next/image';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

const LogoText = forwardRef(({ disabledLink = false, sx }, ref) => {

  let { themeMode } = useSettings();

  const logo = (
    <Box ref={ref} sx={{ width: 168, height: 40, cursor: 'pointer', ...sx }}>
      <Image
        alt="OnThiBangLaiXe.net"
        src={`/logo/logotext.png`}
        width={168}
        height={40}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <NextLink href="/">{logo}</NextLink>;
});

LogoText.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default LogoText;
